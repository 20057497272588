class Api {
    _token = null;

    static get(url) {
        return this.request(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
    }

    static post(url, data) {
        return this.request(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
    }

    static request(url, requestOptions) {
        return new Promise((success, reject) => {
            fetch(url, requestOptions).then(
                (response) => {
                    success(response.json())
                },
                (error) => {
                    reject(error)
                }
            )
        })
    }

    async getAuthorized(url) {
        const token = await this.getToken();

        return Api.request(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'api-key' : token },
        })
    }

    async getToken() {
        if (this._token) {
            return this._token;
        }

        this._token = await Api.get('https://api.umnion.io/madeingermany/token').then((r) => {return r.token});

        return this._token;
    }

    makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }
}

const api = new Api();

export default api;