import api from "./api";
import Category from "./category";
import Product from "./product";

class AppData {
    title = 'App'

    mainCategories = []

    allCategories = []

    products = []

    loadedAll = false

    loadCategories (callback) {
        if (this.mainCategories.length > 0) {
            callback(this.mainCategories)
            return
        }

        // api.getAuthorized('https://api.umnion.io/v1/apps/10354/items?expand=values&gridviewsearch__additional-field_192429=0').then((r) => {
        api.getAuthorized('https://api.umnion.io/v1/apps/10354/items?expand=values&pageSize=200').then((r) => {

            this.allCategories = []

            if (Array.isArray(r)) {
                r.forEach((c) => {
                    const cat = new Category()
                    cat.parseObject(c)
                    this.allCategories.push(cat)
                })
            }

            this.mainCategories = this.allCategories.filter((c) => {
                return c.parentId === 0
            }).sort((a, b) => {
                return a.name < b.name ? -1 : 1
            })

            callback(this.allCategories)
        })
    }

    getLocalCategoryById(id) {
        for (const c of this.allCategories) {
            if (c.id === parseInt(id)) {
                return c
            }
        }

        return null
    }

    getServerCategoryById(id, callback)
    {
        api.getAuthorized(`https://api.umnion.io/v1/items/${id}?expand=values`).then((r) => {
            const c = new Category()

            c.parseObject(r)

            callback(c)
        })
    }

    getChildCategories(id) {
        let cats = []

        for (const c of this.allCategories) {
            if (c.parentId === parseInt(id)) {
                cats.push(c)
            }
        }

        return cats.sort((a, b) => {
            return a.name < b.name ? -1 : 1
        })
    }

    getLocalProductById(id) {
        for (const p of this.products) {
            if (p.id === parseInt(id)) {
                return p
            }
        }

        return null
    }

    getServerProductById(id, callback)
    {
        api.getAuthorized(`https://api.umnion.io/v1/items/${id}?expand=values`).then((r) => {
            const p = new Product()

            p.parseObject(r)

            callback(p)
        })
    }

    loadProducts(categoryId, callback) {
        api.getAuthorized(categoryId ? `https://api.umnion.io/v1/apps/2427/items?expand=values&f192347=${categoryId}` : `https://api.umnion.io/v1/apps/2427/items?expand=values&pageSize=200`).then((r) => {
            this.products = []

            if (Array.isArray(r)) {
                r.forEach((p) => {
                    const prod = new Product()
                    prod.parseObject(p)
                    this.products.push(prod)
                })
            }

            callback(this.products)
        })
    }

    getProductsByCategoryId(categoryId) {
        let prods = []

        if (this.products.length === 0) {
            return null
        }

        for (const p of this.products) {
            if (p.categoryId === parseInt(categoryId)) {
                prods.push(p)
            }
        }

        return prods.sort((a, b) => {
            return a.name < b.name ? -1 : 1
        })
    }

    makeSureDataLoaded(callback) {
        if (this.loadedAll) {
            callback()
            return
        }

        this.loadCategories(() => {
            this.loadProducts(null, () => {
                this.loadedAll = true
                callback()
            })
        })
    }
}

const appdata = new AppData()

export default appdata