import api from "./api";

export default class Category {
    object = {}
    props = {}
    name = ''
    imageUrl = ''
    parentId = 0

    parseObject(obj) {

        Object.assign(this, obj)

        this.props = obj.values ? obj.values.reduce((obj, item) => ({ ...obj, [item.field_id]: item.value }), {}) : {};

        this.name = this.props[192343]

        this.parentId = this.props[192346] ?? 0

        this.setImageUrl(this.props[192344])
    }

    setImageUrl(val) {
        if (val) {
            this.imageUrl = `https://api.umnion.io/v1/files/${val}/download?access-token=${api._token}`
        }
    }
}