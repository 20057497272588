import {
    useParams
} from "react-router-dom";
import NavBar from "../components/NavBar";
import {Button, Container, Placeholder} from "react-bootstrap";
import {useEffect, useState} from "react";
import appdata from "../models/app-data";
import Footer from "../components/Footer";
import {Parallax} from "react-parallax";
import DefaultSpinner from "../components/DefaultSpinner";

export async function loader({ params }) {
    return true;
}

export default function Product() {
    const { productId } = useParams()

    const [data, setData] = useState(appdata.getLocalProductById(productId))

    const [dataLoaded, setDataLoaded] = useState(true)

    useEffect(() => {
        // appdata.makeSureDataLoaded(() => {
            setDataLoaded(true)

            const p = appdata.getLocalProductById(productId)
            setData(p)
            if (p === null) {
                appdata.getServerProductById(productId, (p) => {
                    setData(p)
                })
            }
        // })
    }, [productId])

    return (
        <>
            <NavBar title={data ? data.name : 'Product'}/>

            <Container className={'app-body'}>

                {!dataLoaded && <DefaultSpinner />}

                {false && data && <Parallax
                    bgImage={data.imageUrl}
                    blur={{ min: -15, max: 15 }}
                    strength={-200}
                >
                    <div style={{height:100}}/>
                    <h2>{data.name}</h2>
                    <div style={{height:200}}/>
                </Parallax>}

                {data && <>
                    <br />
                    <img src={data.imageUrl} alt='product' style={{maxWidth: 300}}/>
                    <br />
                </>}

                <br/>

                {(data && <h2>{data.name}</h2> )|| <Placeholder as='h2' animation="wave"><Placeholder  xs={4} /></Placeholder>}

                {(data && <p className='multi-line'>{data.description}</p> )|| <>
                    <Placeholder as='p' animation="wave">
                        <Placeholder  xs={7} /> <Placeholder  xs={4} />
                        <Placeholder  xs={4} /> <Placeholder  xs={6} />
                        <Placeholder  xs={8} />
                    </Placeholder>
                </>}

                {data && data.price && <p>{data.price} €</p>}

                {data && data.url !== '' && <Button href={data.url} target='_blank'>Zur Seite</Button> }

                <br />
                <br />

            </Container>

            <Footer/>
        </>
    )
}