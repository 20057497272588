import {Container, Navbar} from "react-bootstrap";

export default function Footer() {
    return <>

        <Navbar className="bg-body-tertiary footer-bar">

            <Container>
                <Navbar.Text>&copy; 2024 Made in Germany</Navbar.Text>
            </Container>

        </Navbar>
    </>
}