// import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ArrowLeft} from "react-bootstrap-icons";

export default function BackButton() {
    // const [history] = useState(() => window.history)
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            {location.key !== 'default' && <button onClick={() => location.key !== 'default' ? navigate(-1) : navigate('/')} className='nav-link'><ArrowLeft/></button>}
        </>
    )
}