import api from "./api";

export default class Product {
    object = {}
    props = {}
    name = ''
    imageUrl = ''
    description = ''
    categoryId = 0
    url = ''
    price = 0

    parseObject(obj) {

        Object.assign(this, obj)

        this.props = obj.values ? obj.values.reduce((obj, item) => ({ ...obj, [item.field_id]: item.value }), {}) : {};

        this.name = this.props[38767]

        this.categoryId = this.props[192347]

        this.description = this.props[38770]

        this.url = this.props[192349]

        this.price = this.props[41394]

        if (this.url && !this.url.startsWith('http')) {
            this.url = 'https://' + this.url
        }

        this.setImageUrl(this.props[38771])
    }

    setImageUrl(val) {
        if (val) {
            this.imageUrl = `https://api.umnion.io/v1/files/${val}/download?access-token=${api._token}`
        }
    }
}