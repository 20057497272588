import {
    useParams
} from "react-router-dom";
import NavBar from "../components/NavBar";
import {Col, Container, ListGroup, Placeholder, Row} from "react-bootstrap";
import CategoryTile from "../components/CategoryTile";
import {useEffect, useState} from "react";
import appdata from "../models/app-data";
import ProductTile from "../components/ProductTile";
import Footer from "../components/Footer";
import DefaultSpinner from "../components/DefaultSpinner";
import ProductRow from "../components/ProductRow";

export async function loader({ params }) {
    return true;
}


export default function Category() {
    const { categoryId } = useParams()

    const [data, setData] = useState(appdata.getLocalCategoryById(categoryId))

    const [categories, setCategories] = useState(appdata.getChildCategories(categoryId))

    const [products, setProducts] = useState(appdata.getProductsByCategoryId(categoryId))

    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        appdata.makeSureDataLoaded(() => {
            setDataLoaded(true)

            const c = appdata.getLocalCategoryById(categoryId)
            setData(c)
            if (c === null) {
                appdata.getServerCategoryById(categoryId, (c) => {
                    setData(c)
                })
            }

            const prods = appdata.getProductsByCategoryId(categoryId)
            setProducts(prods)
            if (prods === null) {
                appdata.loadProducts(categoryId, () => {
                    setProducts(appdata.getProductsByCategoryId(categoryId))
                })
            }

            setCategories(appdata.getChildCategories(categoryId))

        })

    }, [categoryId])

    return (
        <>
            <NavBar title={data ? data.name : 'Category'}/>

            <Container className={'app-body'}>

                <br/>

                {!dataLoaded && <DefaultSpinner />}

                {/*{(data && <h2>{data.name}</h2> )|| <Placeholder as='h2' animation="wave"><Placeholder  xs={4} /></Placeholder>}*/}

                {(data && data.description && <p className='multi-line'>{data.description}</p> ) || (!data && <>
                    <Placeholder as='p' animation="wave">
                        <Placeholder  xs={7} /> <Placeholder  xs={4} />
                        <Placeholder  xs={4} /> <Placeholder  xs={6} />
                        <Placeholder  xs={8} />
                    </Placeholder>
                </>)}

                {(categories &&
                    <>
                        <br/>
                        <Row xs={2} sm={3} md={3} lg={4} className="g-3">
                            {categories.map((c, k) => (
                                <Col key={`category-tile-${k}`}>
                                    <CategoryTile category={c}/>
                                </Col>
                            ))}
                        </Row>
                        <br/>
                    </>)}

                {(products && products.length > 0 &&
                    <>
                        <h2>Produkte</h2>
                        <br/>
                        <Row xs={2} sm={3} md={3} lg={4} className="g-3">
                            {products.map((p, k) => (
                                <Col key={`product-tile-col-${k}`}>
                                    <ProductTile product={p}/>
                                </Col>
                            ))}
                        </Row>
                        <br/>
                    </>)}

                <br/>

                {(false && products && products.length > 0 &&
                    <>
                        <br/>
                        <h2>Produkte</h2>
                        <br/>
                        <ListGroup>
                            {products.map((p, k) => (
                                <ProductRow product={p} key={`product-row-${k}`}/>
                            ))}
                        </ListGroup>

                    </>)}

            </Container>

            <Footer/>
        </>
    )
}