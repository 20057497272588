import BackButton from "./BackButton";
import {
    Container,
    Nav,
    Navbar,
    // NavDropdown,
} from "react-bootstrap";


export default function NavBar({title}) {
    return <>
        <Navbar expand="lg" className="bg-body-tertiary navbar" sticky={'top'}>
            <Container>
                <Nav className="me-auto back-button-wrapper">
                    <BackButton/>
                </Nav>
                <Navbar.Brand className='centered'>
                    <img src="/header-logo.png" alt="Made In Germany" className={'header-logo'}/>
                    <br />
                    {title}
                </Navbar.Brand>
                <Nav className="me-auto back-button-wrapper">

                </Nav>
                {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
                {/*<Navbar.Collapse id="basic-navbar-nav" className="navbar-right-wrapper">*/}
                    {/*<Nav className="me-auto">*/}
                    {/*    <Nav.Link href="#home">Home</Nav.Link>*/}
                    {/*    <Nav.Link href="#link">Link</Nav.Link>*/}
                    {/*    <NavDropdown title="Dropdown" id="basic-nav-dropdown">*/}
                    {/*        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                    {/*        <NavDropdown.Item href="#action/3.2">*/}
                    {/*            Another action*/}
                    {/*        </NavDropdown.Item>*/}
                    {/*        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                    {/*        <NavDropdown.Divider />*/}
                    {/*        <NavDropdown.Item href="#action/3.4">*/}
                    {/*            Separated link*/}
                    {/*        </NavDropdown.Item>*/}
                    {/*    </NavDropdown>*/}
                    {/*</Nav>*/}
                {/*</Navbar.Collapse>*/}
            </Container>
        </Navbar>
    </>
}