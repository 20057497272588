import {useNavigate} from "react-router-dom";
import {Card} from "react-bootstrap";

export default function CategoryTile({category}) {
    const navigate = useNavigate()

    return (<>

        <Card style={{ width: '100%' }} onClick={() => navigate('/category/' + category.id)} className='clickable-card'>
            <div className={category.imageUrl ? 'card-image filled' : 'card-image empty'}>
                <Card.Img variant="top" src={category.imageUrl}/>
            </div>
            <Card.Body>
                <Card.Title>{category.name}</Card.Title>
                {/*<Card.Text>*/}
                {/*    Some quick example text to build on the card title and make up the*/}
                {/*    bulk of the card's content.*/}
                {/*</Card.Text>*/}
            </Card.Body>
        </Card>
    </>)
}