import {useNavigate} from "react-router-dom";
import {Card} from "react-bootstrap";
import {useEffect, useState} from "react";

export default function ProductTile({product}) {
    const navigate = useNavigate()

    const [image, setImage] = useState(null)

    useEffect(() => {
        setImage(product.imageUrl)
    }, [product])

    return (<>

        <Card style={{ width: '100%' }} onClick={() => navigate('/product/' + product.id)} className='clickable-card'>
            <div className={image ? 'card-image filled' : 'card-image empty'}>
                <Card.Img variant="top" src={image}/>
            </div>
            <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                {/*<Card.Text>*/}
                {/*    Some quick example text to build on the card title and make up the*/}
                {/*    bulk of the card's content.*/}
                {/*</Card.Text>*/}
            </Card.Body>
        </Card>
    </>)
}