import { getContacts, createContact } from "../contacts";
import NavBar from "../components/NavBar";
import {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import CategoryTile from "../components/CategoryTile";
import appdata from "../models/app-data";
import Footer from "../components/Footer";
import DefaultSpinner from "../components/DefaultSpinner";

export async function loader() {
    const contacts = getContacts()
    return { contacts }
}

export async function action() {
    const contact = await createContact()

    return { contact }
}

export default function Root() {
    const [categories, setCategories] = useState(appdata.mainCategories)

    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        appdata.makeSureDataLoaded(() => {
            setDataLoaded(true)
            if (categories === null || categories.length === 0) {
                appdata.loadCategories((c) => {
                    setCategories(appdata.mainCategories)
                })
            }
        })
    }, [categories])

    return (
        <>
            <NavBar title={'Made In Germany'}/>

            <Container className={'app-body'}>

                {!dataLoaded && <DefaultSpinner />}

                {categories &&
                    <>
                        <br/>
                        <br/>
                        <Row xs={2} sm={3} md={3} lg={4} className="g-3">
                            {categories.map((c, k) => (
                                <Col key={`category-tile-${k}`}>
                                    <CategoryTile key={`category-tile-${k}`} category={c}/>
                                </Col>
                            ))}
                        </Row>

                    </>
                }

                <br />
                <br />

            </Container>

            <Footer/>
        </>
    )
}